import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function DeadSea() {
  return (
    <Layout>
      <Helmet title="Dead Sea | The Lumineers" />

      <h1>Dead Sea</h1>

      <h2>The Lumineers</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>I stood alone, upon the platform in vain</p>
          <p>Puerto Ricans they were playing me salsa in the rain</p>
          <p>With open doors and manual locks</p>
          <p>In fast food parking lots</p>
        </Verse>
        <Verse>
          <p>I headed West, I was a man on the move</p>
          <p>New York had lied to me, I needed the truth</p>
          <p>Oh, I need somebody, needed someone I could trust</p>
          <p>I don't gamble, but if I did I would bet on us</p>
        </Verse>
        <Chorus>
          <p>Like the Dead Sea</p>
          <p>You told me I was like the Dead Sea</p>
          <p>You'll never sink when you are with me</p>
          <p>Oh Lord, like the Dead Sea</p>
        </Chorus>
        <Chorus>
          <p>Whoa, I'm like the Dead Sea</p>
          <p>The finest words you ever said to me</p>
          <p>Honey can't you see</p>
          <p>I was born to be, be your Dead Sea</p>
        </Chorus>
        <Verse>
          <p>You told me you were good at running away</p>
          <p>Domestic life, it never suited you like a suitcase</p>
          <p>You left with just the clothes on your back</p>
          <p>You took the rest when you took the map</p>
        </Verse>
        <Verse>
          <p>Yes, there are times we live for somebody else</p>
          <p>Your father died and you decided to live</p>
          <p>It for yourself you felt, you just felt it was time</p>
          <p>And I'm glad, cause you with cats, that's just not right</p>
        </Verse>
        <Chorus>
          <p>Like the Dead Sea</p>
          <p>You told me I was like the Dead Sea</p>
          <p>You'll never sink when you are with me</p>
          <p>Oh Lord, I'm your Dead Sea</p>
        </Chorus>
        <Chorus>
          <p>Whoa, I'm like the Dead Sea</p>
          <p>The nicest words you ever said to me</p>
          <p>Honey can't you see</p>
          <p>I was born to be, be your dead sea</p>
        </Chorus>
        <Bridge>
          <p>I been down, I been defeated</p>
          <p>You're the message I was heeding</p>
          <p>Would you stay</p>
          <p>Would you stay the night?</p>
        </Bridge>
        <Chorus>
          <p>Dead Sea</p>
          <p>Told me I was like the Dead Sea</p>
          <p>Never sink when you are with me</p>
          <p>Oh Lord, I'm your Dead Sea</p>
        </Chorus>
        <Chorus>
          <p>Whoa, I'm like the Dead Sea</p>
          <p>The nicest words you ever said to me</p>
          <p>Honey can't you see</p>
          <p>I was born to be, be the Dead Sea</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
